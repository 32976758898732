import { apiGetCall, apiPostCall } from 'src/services/api/api.core';
import { EApiEndpoint } from 'src/config/enums';
import { applyValues, getCookie } from 'src/utils';
import { TAppointmentDate } from 'src/services/api/api.types';

type TAppointmentBookingRequest = {
    patientId: string;
    clinicId: number;
    date: string;
    timeOfDay: string;
    contactMethod: string;
    practitionerId: string | null;
};

export const postAppointmentBookingRequest = async (data: TAppointmentBookingRequest) => {
    const headers = {
        'X-FBP': getCookie('_fbp'),
        'X-FBC': getCookie('_fbc')
    };

    return await apiPostCall(EApiEndpoint.AppointmentBookingRequest, JSON.stringify(data), {
        headers: headers
    });
};

type TAppointmentBookingRequestTimes = {
    clinicId: number;
    appointmentTypeId: string;
};

export const getAppointmentBookingRequestTimes = async (
    params: TAppointmentBookingRequestTimes
): Promise<TAppointmentDate[] | null> => {
    return await apiGetCall(
        applyValues(EApiEndpoint.AppointmentBookingRequestAppointmentTime, params)
    );
};
