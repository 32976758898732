import {
  TCart,
  TClinic,
  TError,
  TPractitioner,
  TStripeSetupIntent,
  TUser,
} from "src/services/api/api.types";
import {
  checkoutPostCart,
  clinikoGetPractitionerByAppointmentAndTime,
  userCreateAccount,
} from "src/services/api";
import { OnyxError } from "src/helpers/OnyxError";
import { EAlertVariant } from "src/context/AlertContext";
import { CardElement } from "@stripe/react-stripe-js";
import { TBookingFlowContextState } from "src/context/BookingFlowContext";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import { navigate } from "gatsby";
import { ERoutes } from "src/config/enums";
import * as Styled from "src/components/OxBookingSimple/OxBookingSimple.styled";
import React, { Dispatch } from "react";

type TCreateAccountProps = {
  data: FormData;
  clinic?: TClinic;
};

export const createAccount = async ({
  data,
  clinic,
}: TCreateAccountProps): Promise<TUser | null> => {
  return await userCreateAccount({
    clinicId: clinic?.id,
    firstname: data.get("firstname") as string,
    lastname: data.get("lastname") as string,
    email: data.get("email") as string,
    password: "",
    mobile: data.get("mobile") as string,
    newsletter: !!data.get("newsletter"),
    acceptedPrivacyPolicy: !!data.get("terms"),
  });
};

type TCreateCartProps = {
  data: FormData;
  clinic?: TClinic;
  state: TBookingFlowContextState;
  appointmentTypeId: string;
};

export const createCart = async ({
  data,
  state,
  appointmentTypeId,
}: TCreateCartProps): Promise<TCart[] | null> => {
  return await checkoutPostCart({
    clinicId: state.clinic?.id as number,
    practitionerId: data.get("practitionerId") as string,
    preferredPractitionerId: data.get("preferredPractitioner") as string,
    appointmentTypeId: appointmentTypeId as string,
    startsAt: state.consultationTime?.toUTC().toISO() as string,
  });
};

type TGetPractitionerProps = {
  data: FormData;
  state: TBookingFlowContextState;
  appointmentTypeId: string;
};

export const getPractitioner = async ({
  data,
  state,
  appointmentTypeId,
}: TGetPractitionerProps): Promise<TPractitioner | null> => {
  return await clinikoGetPractitionerByAppointmentAndTime(
    {
      clinicId: state.clinic?.id as number,
      appointmentTypeId: appointmentTypeId as string,
      startsAt: state.consultationTime?.toUTC().toISO() as string,
    },
    {
      preferredPractitionerId: data.get("preferredPractitioner") as string,
    }
  );
};

export type TCheckCustomerAccountCallbackProps = {
  userData: TUser;
  data: FormData;
};

type TCheckCustomerAccountProps = {
  callback: ({
    userData,
    data,
  }: TCheckCustomerAccountCallbackProps) => Promise<void>;
  data: FormData;
  state: TBookingFlowContextState;
  dispatch: Dispatch<{ type: any; payload: any }>;
  runClinicCheck?: boolean;
};

export const checkCustomerAccount = async ({
  callback,
  data,
  dispatch,
  state: { user, clinic },
  runClinicCheck = false,
}: TCheckCustomerAccountProps): Promise<void> => {
  let userData: (TUser & Partial<TError>) | null;

  /**
   * if user.id is set
   * AND
   * Either runClinicCheck is false
   * OR runClinicCheck is true and user clinic === selected clinic ID
   */
  if (user?.id && (!runClinicCheck || user?.clinicId === clinic?.id)) {
    userData = user;
  } else {
    userData = await createAccount({ data, clinic });
    dispatch({
      type: EBookingFlowAction.SetUser,
      payload: userData,
    });
  }

  // processing must be set to false before error is thrown, otherwise
  // submit is no longer possible after email address is changed
  if (userData?.error || !userData?.id) {
    if (userData?.error === "User with that email already exists") {
      throw new OnyxError({
        sentryIgnore: true,
        type: EAlertVariant.Confirm,
        title: "Account already exists",
        message: "You must login to create an appointment with this email",
        confirmCTA: "LOGIN",
        hideAfterConfirm: true,
        onConfirm: (): void => {
          navigate(ERoutes.PanelCustomerLogin, {
            state: {
              returnUrl: ERoutes.Appointment,
              forceReLogin: true,
              returnState: {
                loginAutoSubmit: true,
              },
              email: data.get("email"),
            },
          });
        },
      });
    } else {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT ID: OLFS4",
        title: "Something went wrong",
        message: [
          <>
            Please check the information entered is correct
            {!!userData?.detail && (
              <>
                :{" "}
                <Styled.SemiBold>
                  {Object.entries(userData.detail)
                    .map((entry) => entry[0] + " " + entry[1])
                    .join(", ")}
                </Styled.SemiBold>
              </>
            )}
          </>,
          <>
            If you you continue to see this message, please contact us for
            assistance.
          </>,
        ],
      });
    }
  } else {
    dispatch({
      type: EBookingFlowAction.SetNewlyCreatedUser,
      payload: true,
    });
  }

  if (userData?.apiToken && userData?.id) {
    localStorage.setItem("ornx_user_token", userData.apiToken);
    await callback({ userData, data });
  }
};

type TTakePaymentProps = {
  data: FormData;
  state: TBookingFlowContextState;
};

export const takePayment = async ({
  data,
  state,
}: TTakePaymentProps): Promise<TStripeSetupIntent> => {
  if (
    !state.paymentObject?.stripe ||
    !state.paymentObject?.elements ||
    !state.paymentObject?.customerIntent
  ) {
    const errDetails: { [key: string]: boolean } = {
      "Stripe not initialized": !state.paymentObject.stripe,
      "Elements not initialized": !state.paymentObject.elements,
      "Customer Intent not initialized": !state.paymentObject.customerIntent,
    };

    Object.keys(errDetails).forEach((key) => {
      if (!errDetails[key]) delete errDetails[key];
    });

    throw new OnyxError({
      type: EAlertVariant.Error,
      header: "ERROR ALERT ID: STRIPE1",
      message:
        "Failed to initialise payment, if the problem persists please contact us.",
      additionalInfo: Object.keys(errDetails).join(", "),
    });
  }

  // Get a reference to a mounted CardElement. Elements knows how
  // to find your CardElement because there can only ever be one of
  // each type of element.
  const cardElement = state.paymentObject.elements.getElement(CardElement);

  // Use your card Element with other Stripe.js APIs
  const { setupIntent, error } =
    await state.paymentObject.stripe.confirmCardSetup(
      state.paymentObject.customerIntent,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            address: {
              country: data.get("country") as string,
            },
            email: data.get("email") as string,
            name: `${data.get("firstname")} ${data.get("lastname")}`,
            phone: data.get("mobile") as string,
          },
        },
      }
    );

  if (error || !setupIntent) {
    throw new OnyxError({
      type: EAlertVariant.Error,
      header: "ERROR ALERT ID: STRIPE2",
      message: [
        <>
          Sorry, there was an error while taking payment, if the problem
          persists please contact us.
          {error.message && (
            <>
              <br />
              <br />
              <Styled.SemiBold>{error.message}</Styled.SemiBold>
            </>
          )}
        </>,
      ],
      additionalInfo: error ?? "Payment method not returned",
    });
  }

  return setupIntent;
};
