import * as Styled from "./OxBookingSimple.styled";

import React, { useContext, useEffect, useRef, useState } from "react";

import { OxContainer } from "src/components/OxContainer";
import { bookVirtualConsultation } from "src/services/api/virtualConsultation";
import { OnyxError } from "src/helpers/OnyxError";
import {
  AlertContext,
  EAlertVariant,
  TAlertData,
} from "src/context/AlertContext";
import { fireEvent } from "src/helpers/TagManager";
import {
  checkoutPlaceOrder,
  TCheckoutPlaceOrder,
  userGetUser,
  validateAppointment,
} from "src/services/api";
import { OxSpinner } from "src/components/OxSpinner";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import { OxBookingSimpleDetails } from "src/components/OxBookingSimple/components/OxBookingSimpleDetails";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { OxBookingSimpleDateTimePicker } from "src/components/OxBookingSimple/components/OxBookingSimpleDateTimePicker";
import { OxSuccess } from "src/components/OxSuccess/OxSuccess";
import {
  BookingFlowContext,
  EBookingType,
  TBookingFlowContextState,
} from "src/context/BookingFlowContext";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import {
  checkCustomerAccount,
  createCart,
  getPractitioner,
  takePayment,
  TCheckCustomerAccountCallbackProps,
} from "src/components/OxBookingSimple/services/shared";
import { OxBookingSimpleClinicSelector } from "src/components/OxBookingSimple/components/OxBookingSimpleClinicSelector";
import { NO_PREFERRED_PRACTITIONER_KEY } from "src/components/OxBookingSimple/components/OxBookingSimpleDateTimePicker/components/OxPreferredPractitioner";
import { isUserCustomer } from "src/helpers/user";
import { getUserMethods } from "src/services/api/stripe";
import type { TError, TUser } from "src/services/api/api.types";
import { OxInput } from "src/components/OxInput";
import { TSubmitButtonProps } from "src/components/OxForm";
import {
  LocationsData,
  TLocationAddress,
  TLocationDataItem,
} from "src/constants/locations";
import { postAppointmentBookingRequest } from "src/services/api/appointmentBookingRequest";

type TProps = {
  isVirtual?: boolean;
  loginAutoSubmit?: boolean;
};

/**
 * Despite the name, this booking form is now far from simple
 * @param isVirtual
 * @param loginAutoSubmit
 * @constructor
 */
export const OxBookingSimple = ({
  isVirtual,
  loginAutoSubmit,
}: TProps): JSX.Element => {
  const { validateResponse } = useValidateResponse();
  const formRef = useRef<HTMLFormElement | null>(null);

  const { showAlert } = useContext(AlertContext);
  const websiteDataContext = useContext(WebsiteDataContext);
  const { state, paymentDisabled, dispatch, reset } =
    useContext(BookingFlowContext);
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  // const [postSubmitNewlyCreatedUser, setPostSubmitNewlyCreatedUser] =
  //   useState<boolean>(false);
  const [userCheckComplete, setUserCheckComplete] = useState<boolean>(false);

  const type = isVirtual ? EBookingType.Virtual : EBookingType.Appointment;

  const appointmentTypeId =
    (isVirtual
      ? state.clinic?.virtualAppointmentTypeId
      : state.clinic?.defaultAppointmentTypeId) ?? "";

  const submitVirtualConsultation = async (data: FormData): Promise<void> => {
    const result = await bookVirtualConsultation({
      clinicId: state.clinic?.id as number,
      practitionerId: state.clinic?.virtualPractitionerId ?? "",
      appointmentTypeId: appointmentTypeId,
      startsAt: state.consultationTime?.toUTC().toISO() as string,
      consultationType: data.get("consultationtype") as string,
      patient: {
        email: data.get("email") as string,
        firstname: data.get("firstname") as string,
        lastname: data.get("lastname") as string,
        mobile: data.get("phone") as string,
        newsletter: !!data.get("newsletter"),
        acceptedPrivacyPolicy: !!data.get("terms"),
      },
    });

    if (result) {
      if (result.errors || result.status === "error") {
        if (Array.isArray(result.errors) && result.errors[0]) {
          if (result.errors[0].errorType === "INVALID_EMAIL") {
            throw new OnyxError({
              type: EAlertVariant.Error,
              header: "ERROR ALERT: OVC1",
              title: "Invalid email address",
              message:
                "The email address you entered has already submitted a profile. Please check your email inbox for the result. If the problem persists please contact us.",
            });
          } else {
            throw new OnyxError({
              type: EAlertVariant.Error,
              header: "ERROR ALERT: OVC2",
              title: "Something went wrong. Try one more time",
              message:
                "The email address you entered has already submitted a profile. Please check your email inbox for the result. If the problem persists please contact us.",
            });
          }
        }
      }
    } else {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT: OVC3",
        title: "Something went wrong. Try one more time",
        message:
          "The email address you entered has already submitted a profile. Please check your email inbox for the result. If the problem persists please contact us.",
      });
    }
  };

  const getClinicLocationData = (
    field: keyof TLocationDataItem
  ): string | number | TLocationAddress => {
    const clinicId = state.clinic?.id;

    const location = Object.values(LocationsData).find(
      (location) => location.id === clinicId
    );

    return (location ?? LocationsData["london"])[field] ?? "";
  };

  const inPersonRequestCallback = async ({
    data,
    userData,
  }: TCheckCustomerAccountCallbackProps) => {
    if (userData && !userData?.error) {
      const date =
        (typeof state.consultationDate === "string"
          ? state.consultationDate
          : state.consultationDate?.toISODate()) ?? "";

      const timeOfDay =
        (typeof state.consultationTime === "string"
          ? state.consultationTime
          : state.consultationTime?.toISODate()) ?? "";

      const practitionerId =
        data.get("preferredPractitioner") === NO_PREFERRED_PRACTITIONER_KEY
          ? ""
          : data.get("preferredPractitioner");

      const inPersonRequestResponse = await postAppointmentBookingRequest({
        date,
        timeOfDay: timeOfDay.toLowerCase().replace(" ", "_"),
        clinicId: state.clinic?.id as number,
        contactMethod: data.get("contactMethod") as string,
        patientId: userData.patients?.[state.clinic?.id].patientId ?? "",
        practitionerId: (practitionerId as string) || null,
      });

      try {
        validateResponse(inPersonRequestResponse);
      } catch (e: TError) {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT ID: OBS12A",
          message:
            "Sorry something went wrong when processing your request. If the problem persists please contact us.",
          additionalInfo: e.message,
        });
      }
    }
  };

  const inPersonAppointmentCallback = async ({
    data,
    userData,
  }: TCheckCustomerAccountCallbackProps) => {
    const practitioner = await getPractitioner({
      data,
      state,
      appointmentTypeId,
    });

    try {
      validateResponse(practitioner);
    } catch (e) {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT ID: OBS10",
        message:
          "Please check your details and try again, if the problem persists please contact us.",
      });
    }

    if (!practitioner) {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT ID: OBS6A",
        message:
          "Please check your details and try again, if the problem persists please contact us.",
      });
    }

    data.set("practitionerId", practitioner?.practitionerId ?? "");

    const cart = await createCart({ data, state, appointmentTypeId });

    try {
      validateResponse(cart);
    } catch (e) {
      if (cart?.title === "Appointment Forbidden") {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT ID: OBS11a",
          title: "A booking already exists",
          message: (
            <>
              It looks like you already have an existing appointment booked in
              your calendar with us. Please go to your profile to amend it or
              give us a call on{" "}
              <a
                style={{ display: "inline" }}
                href={`tel:${getClinicLocationData("phone")}`}
              >
                {getClinicLocationData("phoneFormatted")}
              </a>{" "}
              and we will gladly implement the change for you.
            </>
          ),
        });
      } else {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT ID: OBS11b",
          message:
            "Please check your details and try again, if the problem persists please contact us.",
        });
      }
    }

    if (userData && !userData?.error) {
      const placeOrderBody: TCheckoutPlaceOrder = {};

      if (!state.hasPaymentMethods && !paymentDisabled) {
        const intent = await takePayment({ data, state });
        placeOrderBody.id = intent.id;
        placeOrderBody.payment_method = intent.payment_method;
      }

      const placeOrderResponse = await checkoutPlaceOrder(placeOrderBody);

      if (
        placeOrderResponse?.error ===
        "Previously stored card is invalid, please update the card"
      ) {
        dispatch({
          type: EBookingFlowAction.SetHasPaymentMethods,
          payload: false,
        });

        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT ID: OBS5A",
          message:
            "Sorry something went wrong when checking existing payment details, please enter new details. If the problem persists please contact us.",
        });
      }

      if (!placeOrderResponse?.id) {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT ID: OBS6A-2",
          message:
            "Please check your details and try again, if the problem persists please contact us.",
          additionalInfo:
            placeOrderResponse?.error ?? "No additional error information",
        });
      }
    } else {
      throw new OnyxError({
        type: EAlertVariant.Error,
        header: "ERROR ALERT ID: OBS6A-3",
        message:
          "Please check your details and try again, if the problem persists please contact us.",
        additionalInfo: userData?.error ?? "No additional error information",
      });
    }
    // We don't want to setProcessing(false) here, because submission has been successful
    // and setting this will cause spamming of the submit button to
    // dispatch({
    //       type: EBookingFlowAction.SetProcessing,
    //       payload: false
    //     })
  };

  const submitInPersonAppointment = async (
    data: FormData,
    overrideUser?: TUser
  ): Promise<void> => {
    let overrideState: TBookingFlowContextState | false = false;

    if (overrideUser) {
      overrideState = {
        user: overrideUser,
        clinic: state.clinic,
      } as TBookingFlowContextState;
    }

    await checkCustomerAccount({
      data,
      state: overrideState ? overrideState : state,
      dispatch,
      callback: inPersonRequestCallback,
      // callback: inPersonAppointmentCallback,
      runClinicCheck: true,
    });
  };

  const newEmailRef = useRef<HTMLInputElement>();

  const collectNewEmail = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
      showAlert({
        type: EAlertVariant.Confirm,
        header:
          "Your account is not associated with the selected clinic, please enter a new email address for this booking",
        message: <OxInput name="newEmail" ref={newEmailRef} />,
        onConfirm: () => {
          const email = newEmailRef.current?.value;

          email ? resolve(email) : reject();
        },
        hideAfterConfirm: true,
        onCancel: () => reject(),
      } as TAlertData);
    });
  };

  const submit = async (overrideUser?: TUser): Promise<void> => {
    const form = formRef.current;
    if (!form) return;

    const skipProcessingCheck = form.dataset.skipProcessingCheck === "true";

    if (state.processing && !skipProcessingCheck) return;

    dispatch({
      type: EBookingFlowAction.SetProcessing,
      payload: true,
    });

    const data = new FormData(form);

    const userClinicId = (overrideUser ?? state.user)?.clinicId;

    if (userClinicId && userClinicId !== state.clinic?.id) {
      try {
        const newEmail = await collectNewEmail();
        data.set("email", newEmail);
      } catch (e) {
        // No email entered
        return;
      }
    }

    fireEvent({ event: `${type}FormSubmitted` });
    try {
      if (!state.consultationDate) {
        throw new OnyxError({
          sentryIgnore: true,
          type: EAlertVariant.Error,
          header: "ERROR ALERT: OVC5",
          title: "Please select date",
          message:
            "Please select a date from the calendar before submitting. If the problem persists please contact us.",
        });
      }

      if (!state.consultationTime) {
        throw new OnyxError({
          sentryIgnore: true,
          type: EAlertVariant.Error,
          header: "ERROR ALERT: OVC7",
          title: "Please select time",
          message:
            "Please select a time from the displayed options before submitting. If the problem persists please contact us.",
        });
      }

      if (!state.clinic?.id) {
        throw new OnyxError({
          type: EAlertVariant.Error,
          header: "ERROR ALERT: OVC8",
          title: "Please select time",
          message:
            "Sorry something went wrong, please reload the page and try again. If the problem persists please contact us.",
        });
      }

      if (data.get("preferredPractitioner") === NO_PREFERRED_PRACTITIONER_KEY) {
        data.set("preferredPractitioner", "");
      }

      if (state.shouldValidateAppointment) {
        const response = await validateAppointment({
          clinicId: state.clinic?.id,
          appointmentTypeId,
          startsAt: state.consultationTime?.toUTC().toISO() as string,
        });

        validateResponse(response);

        if (!response?.available) {
          throw new OnyxError({
            sentryIgnore: true,
            type: EAlertVariant.Error,
            header: "ERROR ALERT: OVC9",
            message:
              "Sorry this appointment is no longer available, if the problem persists please contact us.",
          });
        }
      }

      await (isVirtual
        ? submitVirtualConsultation(data)
        : submitInPersonAppointment(data, overrideUser)
      )
        .then(() => {
          fireEvent({ event: `${type}FormSuccess` });
          setFormData({
            name: data.get("firstname"),
            surname: data.get("lastname"),
            consultationDate:
              state.consultationDate?.toFormat?.("d MMM yyyy") ??
              state.consultationDate,
            consultationTime:
              state.consultationTime?.toFormat?.("HH:mm") ??
              state.consultationTime,
            clinicId: state?.clinic?.id,
            contactMethod: data.get("contactMethod") as string,
          });
          setFormSubmitted(true);
          // setPostSubmitNewlyCreatedUser(state.newlyCreatedUser ?? false);
          reset();
          // Don't move this to Finally as we only want to set this to false after everythings run
          dispatch({
            type: EBookingFlowAction.SetProcessing,
            payload: false,
          });
        })
        .catch((e) => {
          dispatch({
            type: EBookingFlowAction.SetProcessing,
            payload: false,
          });
          showAlert(
            e.error ?? {
              type: EAlertVariant.Error,
              header: "ERROR ALERT: OVC10",
              title: "Sorry something went wrong",
              message:
                "Sorry something went wrong, please reload the page and try again. If the problem persists please contact us.",
              additionalInfo: e.message,
            }
          );
        });
    } catch (e) {
      fireEvent({
        event: `${type}FormError`,
        props: { error: e.error },
      });
      dispatch({
        type: EBookingFlowAction.SetProcessing,
        payload: false,
      });
      console.error(e);
      showAlert({
        ...e.error,
        header: e.error?.header ?? "Something went wrong",
        message: e.error?.message ?? e.message,
      });
    }
  };

  const checkAndShowClinicModal = (): void => {
    if (
      userCheckComplete &&
      websiteDataContext.locations.length > 0 &&
      !formSubmitted
    ) {
      if (state.user?.clinicId && !state.clinic) {
        const clinic = websiteDataContext.locations.find(
          (location) => location.id === state.user?.clinicId
        );

        clinic &&
          dispatch({
            type: EBookingFlowAction.SetClinic,
            payload: clinic,
          });
      } else if (!state.clinic) {
        showAlert({
          type: EAlertVariant.Success,
          header:
            "Please select your preferred clinic before requesting an appointment",
          buttonId: "clinicSelectPopupConfirm",
          message: (
            <OxBookingSimpleClinicSelector
              name="chosen_clinic_modal"
              appointmentTypeId={appointmentTypeId}
            />
          ),
        });
      }
    }
  };

  useEffect(checkAndShowClinicModal, [
    websiteDataContext.locations,
    userCheckComplete,
  ]);

  const removeLoginAutoSubmitFromHistoryState = (): void => {
    const historyState = { ...window.history.state };

    historyState.loginAutoSubmit = false;

    window.history.replaceState(
      historyState,
      document.title,
      window.location.pathname
    );
  };

  useEffect(() => {
    let unmounted = false;

    const asyncInit = async (): Promise<void> => {
      if (loginAutoSubmit) {
        removeLoginAutoSubmitFromHistoryState();
        const user = await userGetUser();

        try {
          validateResponse(user);
          dispatch({
            type: EBookingFlowAction.SetUser,
            payload: user,
          });
          submit(user);

          return;
        } catch (e) {
          console.log("login auto submit but not user");
        }
      }

      userGetUser()
        .then(validateResponse)
        .then((response) => {
          isUserCustomer(response).then((isCustomer) => {
            if (!unmounted && isCustomer && response?.id) {
              if (isCustomer && response?.id) {
                dispatch({
                  type: EBookingFlowAction.SetUser,
                  payload: response,
                });
                dispatch({
                  type: EBookingFlowAction.SetClient,
                  payload: {
                    isLoggedIn: true,
                    email: response.email,
                    firstName: state.client?.firstName ?? response.firstname,
                    lastName: state.client?.secondName ?? response.lastname,
                    mobile: state.client?.mobile ?? response.mobile,
                  },
                });

                getUserMethods()
                  .then(validateResponse)
                  .then(({ data }) => {
                    if (data && data.length > 0) {
                      dispatch({
                        type: EBookingFlowAction.SetHasPaymentMethods,
                        payload: true,
                      });
                    }
                  })
                  .catch((e) => console.error(e));
              }
            }
          });
        })
        .catch((e) => console.info(e))
        .finally(() => setUserCheckComplete(true));

      // Component has been loaded in and we're running a different
      // appointment type to what is loaded into the state, so we should reset
      if (state.type !== type) {
        dispatch({
          type: EBookingFlowAction.SetBookingFlowState,
          payload: {
            type,
          },
        });
      }
    };

    asyncInit();

    return () => {
      unmounted = true;
    };
  }, []);

  return (
    <Styled.Container>
      {state.processing && !formSubmitted && (
        <OxSpinner
          addTranslate
          style={{
            left: "50%",
            top: "45%",
            position: "absolute",
            opacity: 0.8,
          }}
        />
      )}
      {formSubmitted && (
        <Styled.SuccessWrapper>
          <OxSuccess
            show={formSubmitted}
            formData={formData}
            summaryTitle={`Your ${
              isVirtual ? "Consultation" : "Booking"
            } Summary`}
            bottomText={
              isVirtual
                ? `If you want to amend or cancel this conversation, please contact london@ouronyx.com`
                : undefined
            }
            type={isVirtual ? "callBack" : "booking"}
            position="relative"
            noHeight
          />
        </Styled.SuccessWrapper>
      )}
      <OxContainer>
        <Styled.Title>Request appointment</Styled.Title>
        <Styled.Heading>
          {isVirtual
            ? "Please book a date, time and a type of conversation with one of Ouronyx’s experts."
            : "We look forward to welcoming you to Ouronyx. Please submit a request below to your preferred clinic location.\n"}
        </Styled.Heading>
      </OxContainer>

      <Styled.CallbackWrapper
        onFormSubmit={(formData: FormData, e: SubmitEvent): void => {
          const { userData } =
            e.detail ?? e.target?.detail ?? e.nativeEvent?.detail ?? {};
          submit(userData);
        }}
        formSubmitted={formSubmitted}
        ref={formRef}
        id={`${isVirtual ? "virtual" : "booking"}-form`}
      >
        {({ submitButtonProps }: { submitButtonProps: TSubmitButtonProps }) =>
          !!state.clinic && (
            <Styled.CallbackContainer
              key={`Styled.CallbackContainer-${state.clinic?.id}`}
            >
              <OxBookingSimpleDateTimePicker
                key={`OxBookingSimpleDateTimePicker-${state.clinic?.id}`}
                isVirtual={isVirtual}
                appointmentTypeId={appointmentTypeId}
                type={type}
              />
              <OxBookingSimpleDetails
                isVirtual={isVirtual}
                type={type}
                user={state.user}
                submitButtonProps={submitButtonProps}
                formSubmit={(): Promise<void> => submit()}
              />
            </Styled.CallbackContainer>
          )
        }
      </Styled.CallbackWrapper>
    </Styled.Container>
  );
};
