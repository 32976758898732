import React, { MouseEvent, useContext } from "react";
import { OxSelect } from "src/components/OxSelect";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import { fireEvent } from "src/helpers/TagManager";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import {
  BookingFlowContext,
  EBookingType,
} from "src/context/BookingFlowContext";

export const NO_PREFERRED_PRACTITIONER_KEY = "no-preferred-practitioner";

type TSelect = { label: string; value: string };

type TProps = {
  type: EBookingType;
};

export const OxPreferredPractitioner = ({ type }: TProps): JSX.Element => {
  const websiteDataContext = useContext(WebsiteDataContext);
  const { state, dispatch } = useContext(BookingFlowContext);

  const fireGaEvent = (e: Event | MouseEvent<HTMLInputElement>): void => {
    const target = e.target as HTMLInputElement;
    fireEvent({ event: target.name + "InputClickInSimpleBooking" });
  };

  // Practitioner ID needed to match against logged in customers
  const doctorSelectOptions =
    websiteDataContext.doctors
      ?.filter(
        (doctor) =>
          doctor.showInOnlineBookings && doctor.clinicId === state.clinic?.id
      )
      .sort(function (a, b) {
        const nameA = a.lastName?.toUpperCase() ?? "";
        const nameB = b.lastName?.toUpperCase() ?? "";
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names might be equal
        return 0;
      })
      .map((item) => ({
        label: `${item.title} ${item.label} (${state.clinic?.label} Clinic)`,
        value: item.practitionerId,
      })) ?? ([] as TSelect[]);

  doctorSelectOptions.unshift({
    label: "No Preference",
    value: NO_PREFERRED_PRACTITIONER_KEY,
  });

  // Hardcdded I know! 🤢
  if (state.clinic?.id === 1) {
    doctorSelectOptions.push({
      label: "Facial Aesthetician (London Clinic)",
      value: "1100566458271799057",
    });
  }
  if (state.clinic?.id === 2) {
    doctorSelectOptions.push({
      label: "Facial Aesthetician (Dubai Clinic)",
      value: "1105614519972929432",
    });
  }

  return (
    <>
      <OxSelect
        name="preferredPractitioner"
        title="Practitioner Preference"
        labelName="label"
        valueName="value"
        initialValue={doctorSelectOptions.find(
          (option) => option.value === state.preferredPractitioner
        )}
        items={doctorSelectOptions}
        onClick={fireGaEvent}
        onValueChange={(val: TSelect): void => {
          dispatch({
            type: EBookingFlowAction.SetPreferredPractitioner,
            payload: val?.value,
          });

          if (!val) return;
          // Yes, currently type will only ever be 'appointment' for when
          // this fires, but in case this changes in the future, we may as well
          // handle this properly
          fireEvent({
            event: `${type}FormDoctorSelected`,
            props: {
              preferredDoctor: val.label,
            },
          });
        }}
      />
    </>
  );
};
