import { EApiEndpoint } from "src/config/enums";
import { apiPostCall } from "./api.core";

export const bookVirtualConsultation = async (data: {
  clinicId: number;
  practitionerId: string;
  appointmentTypeId: string;
  startsAt: string;
  consultationType: string;
  patient: {
    email: string;
    firstname: string;
    lastname: string;
    mobile: string;
    newsletter: boolean;
    acceptedPrivacyPolicy: boolean;
  };
}): Promise<any> => {
  return await apiPostCall(
    EApiEndpoint.VirtualAppointment,
    JSON.stringify(Object.assign(data))
  );
};
