import * as Styled from "./OxBookingSimpleDateTimePicker.styled";

import React, { useContext, useEffect, useState } from "react";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { OxSpinner } from "src/components/OxSpinner";
import { OxIcon } from "src/components/OxIcon";
import { DateTime } from "luxon";
import {
  clinikoRestrictedDates,
  getAppointmentTimesByClinic,
  getAppointmentTimesByPractitioner,
} from "src/services/api";
import { useValidateResponse } from "src/hooks/useValidateResponse";
import { TAppointmentDate, TError } from "src/services/api/api.types";
import { AlertContext, EAlertVariant } from "src/context/AlertContext";
import { EMode, TAvailableTime } from "src/components/OxTimeSelect";
import { fireEvent } from "src/helpers/TagManager";
import {
  BookingFlowContext,
  EBookingType,
} from "src/context/BookingFlowContext";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import { OxBookingSimpleClinicSelector } from "src/components/OxBookingSimple/components/OxBookingSimpleClinicSelector";
import { OxPreferredPractitioner } from "src/components/OxBookingSimple/components/OxBookingSimpleDateTimePicker/components/OxPreferredPractitioner";
import { getAppointmentBookingRequestTimes } from "src/services/api/appointmentBookingRequest";

type TProps = {
  isVirtual?: boolean;
  appointmentTypeId: string;
  type: EBookingType;
};

let loadingMonths: string[] = [];
let loadedMonths: string[] = [];

export const OxBookingSimpleDateTimePicker = ({
  isVirtual,
  appointmentTypeId,
  type,
}: TProps): JSX.Element => {
  const { validateResponse } = useValidateResponse();
  const { showAlert } = useContext(AlertContext);
  const { state, dispatch } = useContext(BookingFlowContext);

  const [minDate, setDateMin] = useState<DateTime | undefined>();
  const [maxDate, setDateMax] = useState<DateTime | undefined>();

  const [availableDates, setAvailableDates] = useState<TAppointmentDate[]>([]);
  const [availableTimes, setAvailableTimes] = useState<TAvailableTime[]>([]);
  const [currentMonth, setCurrentMonth] = useState<DateTime | undefined>(
    state.consultationDate
  );
  const [defaultActiveStartDate, setDefaultActiveStartDate] = useState<Date>();

  const onBack = (): void => {
    dispatch({
      type: EBookingFlowAction.SetCurrentStage,
      payload: 0,
    });
    dispatch({
      type: EBookingFlowAction.SetConsultationTime,
      payload: undefined,
    });
  };

  const onDateSelect = (date: DateTime): void => {
    if (!date) {
      showAlert({
        type: EAlertVariant.Error,
        header: "ERROR ALERT: OVC4",
        title: "Please select date",
        message:
          "Please select a date from the calendar before submitting. If the problem persists please contact us.",
      });
      return;
    }

    fireEvent({
      event: `${type}FormDate${
        !state.consultationDate ? "Selected" : "Changed"
      }`,
    });

    dispatch({
      type: EBookingFlowAction.SetConsultationDate,
      payload: date,
    });

    dispatch({
      type: EBookingFlowAction.SetCurrentStage,
      payload: 1,
    });
  };

  const onTimeSelect = (date: DateTime): void => {
    fireEvent({
      event: `${type}FormTime${
        !state.consultationDate ? "Selected" : "Changed"
      }`,
    });
    dispatch({
      type: EBookingFlowAction.SetConsultationTime,
      payload: date,
    });
  };

  const getStartOfMonthFromDate = (date: DateTime): string => {
    return date.set({ day: 1 }).toISODate().toString();
  };

  const fetchAppointmentDates = async (date: DateTime): Promise<void> => {
    if (!state.clinic) return;
    const dateValue = getStartOfMonthFromDate(date);

    if (
      !loadingMonths.includes(dateValue) &&
      !loadedMonths.includes(dateValue)
    ) {
      loadingMonths.push(dateValue);

      let result: (TAppointmentDate[] & Partial<TError>) | null;

      if (isVirtual) {
        result = await getAppointmentTimesByPractitioner({
          clinicId: state.clinic?.id ?? "",
          appointmentTypeId: appointmentTypeId,
          practitionerId: state.clinic?.virtualPractitionerId ?? "",
          date: dateValue,
        });
      } else {
        result = await getAppointmentBookingRequestTimes({
          clinicId: state.clinic?.id ?? 0,
          appointmentTypeId: appointmentTypeId,
        });
      }

      try {
        validateResponse(result);
      } catch (e) {
        console.error(e.message);
        showAlert(e.error);
        loadingMonths = loadingMonths.filter((val) => val !== dateValue);
        // loadedMonths = loadedMonths.filter(val => val !== dateValue);
        return;
      }

      if (result) {
        const times = result.map((item: TAppointmentDate) => ({
          startsAt: DateTime.fromISO(item.startsAt).setZone(
            state.clinic?.timezone ?? "Europe/London"
          ),
          available: item.available,
        }));
        loadingMonths = loadingMonths.filter((val) => val !== dateValue);
        loadedMonths.push(dateValue);
        setAvailableTimes((prev) => [...prev, ...times]);
        setAvailableDates((prev) => [
          ...prev,
          ...(result as TAppointmentDate[]),
        ]);

        if (
          result.filter((item) => item.available).length === 0 ||
          (state.consultationTime &&
            typeof state.consultationTime === "object" &&
            dateValue < getStartOfMonthFromDate(state.consultationTime))
        ) {
          if (
            (date?.diff(currentMonth || DateTime.local(), "months").months ||
              0) < 5
          ) {
            await fetchAppointmentDates(date.plus({ month: 1 }));
          } else if (!defaultActiveStartDate) {
            setDefaultActiveStartDate(new Date());
          }
        }
      }
    }
  };

  const handleMonthChange = (month: DateTime): void => {
    setCurrentMonth(month);
    fetchAppointmentDates(month);
  };

  useEffect(() => {
    if (!defaultActiveStartDate && (availableDates?.length || 0) > 0) {
      const firstAvailableDate = availableDates.filter(
        (date) => date.available
      );

      if (firstAvailableDate.length) {
        const date = DateTime.fromISO(
          firstAvailableDate[0].startsAt
        ).toJSDate();
        setDefaultActiveStartDate(date);
      }
    }
  }, [availableDates]);

  useEffect(() => {
    fetchAppointmentDates(DateTime.utc());
  }, [state.clinic]);

  useEffect(() => {
    let unmounted = false;
    if (!state.clinic?.id) {
      return;
    }

    fetchAppointmentDates(DateTime.utc());

    clinikoRestrictedDates({ clinicId: state.clinic.id })
      .then(validateResponse)
      .then((data) => {
        if (!unmounted) {
          data?.start && setDateMin(DateTime.fromISO(data?.start));
          data?.end && setDateMax(DateTime.fromISO(data?.end));
        }
      })
      .catch((e) => console.error(e));

    if (state.consultationDate) {
      dispatch({
        type: EBookingFlowAction.SetCurrentStage,
        payload: 1,
      });
    }

    return (): void => {
      unmounted = true;
      loadedMonths = [];
      loadingMonths = [];
    };
  }, []);

  const spinner = (!defaultActiveStartDate ||
    (currentMonth &&
      loadingMonths.includes(getStartOfMonthFromDate(currentMonth)))) && (
    <OxSpinner
      addTranslate
      style={{
        left: "50%",
        top: "45%",
        position: "absolute",
        opacity: 0.8,
      }}
    />
  );

  const timeSlots: TAvailableTime[] = [
    {
      startsAt: "Morning",
      available: true,
    },
    {
      startsAt: "Early Afternoon",
      available: true,
    },
    {
      startsAt: "Late Afternoon",
      available: true,
    },
  ];

  return (
    <div>
      <OxThemedInput theme={EInputTheme.GoldTransparent}>
        <OxBookingSimpleClinicSelector
          name="chosen_clinic"
          appointmentTypeId={appointmentTypeId}
          addTopPadding
        />
        <Styled.AnimatedBoxWrapper>
          <Styled.AnimationContainer
            isActive={state.currentStage === 0}
            isNext={false}
            horizontal
          >
            <Styled.Header>
              <Styled.Heading2>Pick A Date</Styled.Heading2>
            </Styled.Header>
            {spinner}
            {!!defaultActiveStartDate && (
              <Styled.Calendar
                selectedDate={state.consultationDate}
                clickableDates={
                  availableDates &&
                  availableDates
                    .filter((date) => date.available)
                    .map((date) => date.startsAt)
                }
                defaultActiveStartDate={defaultActiveStartDate}
                onDateSelect={onDateSelect}
                onMonthChange={handleMonthChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
          </Styled.AnimationContainer>
          <Styled.AnimationContainer
            isActive={state.currentStage === 1}
            isNext={(state.currentStage ?? 0) < 1}
            horizontal
          >
            <Styled.Header>
              <Styled.Heading2>Pick A Timeslot</Styled.Heading2>
              <Styled.BackButton type="button" onClick={onBack}>
                <OxIcon name="arrow-2" />
                {`${
                  state.consultationDate &&
                  state.consultationDate.setLocale("en").toFormat("EEE d MMM y")
                }`}
              </Styled.BackButton>
            </Styled.Header>
            {spinner}
            {availableTimes && (
              <Styled.TimeSelector
                selectedTime={state.consultationTime}
                availableHours={timeSlots}
                mode={EMode.SIMPLE}
                onTimeSelect={onTimeSelect}
              />
            )}
            {!isVirtual && <OxPreferredPractitioner type={type} />}
          </Styled.AnimationContainer>
        </Styled.AnimatedBoxWrapper>
      </OxThemedInput>
    </div>
  );
};
