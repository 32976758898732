import styled, { css } from "styled-components";
import { fluidSizing } from "src/utils";
import { OxBookingAnimation } from "src/components/OxBookingAnimation";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { OxCalendar } from "src/components/OxCalendar";
import { OxTimeSelect } from "src/components/OxTimeSelect";

export const AnimatedBoxWrapper = styled.div`
  position: relative;
  flex: 1;
  ${fluidSizing([
    { prop: "margin-bottom", values: [20, 20, 20] },
    { prop: "margin-top", values: [20, 20, 20] },
  ])}
`;

export const AnimationContainer = styled(OxBookingAnimation)`
  display: flex;
  flex-direction: column;
`;

export const Heading2 = styled.h2`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Medium,
    [22, 22, 30],
    [28, 28, 38]
  )}
`;

export const Calendar = styled(OxCalendar)`
  ${fluidSizing([{ prop: "height", values: [300, 380, 380] }])}

  .react-calendar {
    ${fluidSizing([{ prop: "padding", values: [20, 20, 20] }])};
    &__navigation {
      ${fluidSizing([
        { prop: "height", values: [40, 40, 40] },
        { prop: "margin-bottom", values: [20, 20, 20] },
      ])};
    }
  }
`;

export const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${fluidSizing([{ prop: "margin-bottom", values: [15, 20, 20] }])}
    ${theme.breakpoints.only("xs")} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
);

export const TimeSelector = styled(OxTimeSelect)`
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const BackButton = styled.button`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.SemiBold,
    [12, 12, 12],
    [12, 12, 12]
  )}
  svg {
    ${fluidSizing([
      { prop: "height", values: [5, 5, 5] },
      { prop: "margin-right", values: [5, 5, 5] },
      { prop: "margin-bottom", values: [2, 2, 2] },
    ])}
    transform: rotate(180deg);
  }
`;
