import styled, { css } from "styled-components";
import { EFonts, EFontWeight } from "src/config/enums";
import { createTextStyle } from "src/utils/createTextStyle";
import { OxRadioList } from "src/components/OxRadioList";
import { fluidSizing } from "src/utils";

export const AlreadyAClient = styled.span`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [9, 9, 9],
    [11, 11, 11]
  )}
`;

export const RadioList = styled(OxRadioList)<{ addTopPadding?: boolean }>(
  ({ addTopPadding }) => css`
    ${addTopPadding &&
      fluidSizing([{ prop: "padding-top", values: [20, 20, 0] }])}
  `
);
