import * as Styled from "./OxBookingSimplePaymentForm.styled";

import React, { useContext, useEffect, useRef, useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { graphql, useStaticQuery } from "gatsby";
import { EInputTheme, OxThemedInput } from "src/components/OxThemedInput";
import { BookingFlowContext } from "src/context/BookingFlowContext";
import { EBookingFlowAction } from "src/components/OxBooking/OxBookingFlowReducer";
import { STRIPE_CARD_OPTIONS } from "src/config/constants";
import { submitForm } from "src/utils/form";
import { fireEvent } from "src/helpers/TagManager";
import { OxLink } from "src/components/OxLink";
import { EModalId } from "src/components/OxHygene";
import { LayoutContext } from "src/context/LayoutContext";
import { WebsiteDataContext } from "src/context/WebsiteDataContext";
import { CONFIG_PATH } from "src/services/api/config";
import getSymbolFromCurrency from "currency-symbol-map";

type TCountry = {
  text: string;
  dial_code: string;
  value: string;
};

type TProps = {
  active?: boolean;
};

export const OxBookingSimplePaymentForm = ({ active }: TProps): JSX.Element => {
  const { geoIpCountry, getConfigValue } = useContext(WebsiteDataContext);
  const { setModalId } = useContext(LayoutContext);
  const { state, dispatch } = useContext(BookingFlowContext);

  const submitButtonRef = useRef<HTMLButtonElement>();

  const stripe = useStripe();
  const elements = useElements();

  const data = useStaticQuery(
    graphql`
      query {
        countries: allCountriesJson {
          nodes {
            text
            value
          }
        }
      }
    `
  );

  const [country, setCountry] = useState<string>("GB");

  const [error, setError] = useState<unknown>();
  const [cardComplete, setCardComplete] = useState<boolean>(false);

  const canSubmit = stripe && elements && !error && cardComplete;

  const onSubmit = (): void => {
    if (!canSubmit) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    dispatch({
      type: EBookingFlowAction.SetProcessing,
      payload: true
    });

    const target = submitButtonRef.current as HTMLButtonElement;
    const form = target?.form;
    if (!form) {
      dispatch({
        type: EBookingFlowAction.SetProcessing,
        payload: false
      });
      return;
    }

    submitForm(form);
  };

  useEffect(() => {
    geoIpCountry && setCountry(geoIpCountry);
  }, [geoIpCountry]);

  useEffect(() => {
    dispatch({
      type: EBookingFlowAction.SetStripe,
      payload: stripe
    });
  }, [stripe]);

  useEffect(() => {
    dispatch({
      type: EBookingFlowAction.SetStripeElements,
      payload: elements
    });
  }, [elements]);

  useEffect(() => {
    dispatch({
      type: EBookingFlowAction.SetStripe,
      payload: stripe
    });

    dispatch({
      type: EBookingFlowAction.SetStripeElements,
      payload: elements
    });
  }, []);

  const cancellationFee = getConfigValue(
    CONFIG_PATH.APPOINTMENT_CANCELLATION_FEE_OVERRIDE,
    state.clinic?.id
  );

  return (
    <Styled.Container>
      <Styled.Note>
        Your card will not be charged, we only need card details to guarantee
        your appointment. If you cancel within 48 hours of the appointment or do
        not turn up, a cancellation fee of{" "}
        {getSymbolFromCurrency(state.clinic?.currency)}
        {cancellationFee} will be charged.
      </Styled.Note>
      <OxThemedInput theme={EInputTheme.GoldAlternative}>
        <Styled.BillingSelect
          name="country"
          title="Country"
          items={data.countries.nodes}
          value={country}
          onValueChange={(val: TCountry) => {
            val && setCountry(val.value);
          }}
          labelName="text"
          valueName="value"
          // onClick={fireGaEvent}
          required={active}
        />
        <Styled.CardWrapper>
          <CardElement
            options={STRIPE_CARD_OPTIONS}
            onChange={e => {
              setError(e.error);
              setCardComplete(e.complete);
            }}
            onFocus={() => {
              fireEvent({ event: `${state.type}StripeClickedInPayment` });
            }}
          />
        </Styled.CardWrapper>
        <Styled.Cms>
          By clicking Book Appointment you are agreeing to (and all appointments
          are subject to) our{" "}
          <OxLink
            onClick={(): void => {
              setModalId(EModalId.Cancellation);
            }}
          >
            Booking and Cancellations Policy
          </OxLink>
          .
        </Styled.Cms>
        <Styled.Button
          ref={submitButtonRef}
          icon
          type="button"
          onClick={onSubmit}
          loading={state.processing}
          disabled={state.processing}
          debounceClick
          debounceClickDeps={[canSubmit, submitButtonRef]}
        >
          Book Appointment
        </Styled.Button>
      </OxThemedInput>
    </Styled.Container>
  );
};
