import * as Styled from "./OxBookingSimpleDetails.styled";

import React, { useContext, useState } from "react";

import { OxBookingSimpleStep1 } from "src/components/OxBookingSimple/components/OxBookingSimpleDetails/components/OxBookingSimpleStep1";
import { OxBookingSimpleStep2 } from "src/components/OxBookingSimple/components/OxBookingSimpleDetails/components/OxBookingSimpleStep2/OxBookingSimpleStep2";
import { EAnimatedWrapperAnimation } from "src/components/OxAnimatedWrapper";
import { TClinic, TUser } from "src/services/api/api.types";
import { BookingFlowContext } from "src/context/BookingFlowContext";
import { TSubmitButtonProps } from "src/components/OxForm";

type TStep = {
  component: React.FC<TStepProps>;
  isDisabled?: boolean;
  props: TStepProps;
};

export type TStepProps = {
  isVirtual?: boolean;
  submitButtonProps: TSubmitButtonProps;
  onBack: () => void;
  onNext?: () => void;
  active?: boolean;
  activeStepId: number;
  user?: TUser | null;
  clinic?: TClinic;
};

type TProps = {
  isVirtual?: boolean;
  type: string;
  user?: TUser | null;
  submitButtonProps: unknown;
  formSubmit: () => Promise<void>;
};

export const OxBookingSimpleDetails = ({
  isVirtual,
  submitButtonProps,
  formSubmit,
  user,
}: TProps): JSX.Element => {
  const { paymentDisabled, state } = useContext(BookingFlowContext);
  const [activeStepId, setActiveStepId] = useState<number>(0);

  const onBack = (): void => {
    setActiveStepId((prev) => (prev <= 0 ? 0 : prev - 1));
  };

  /**
   * Does not include onNext because function references steps so it's declaration
   * must be further down
   */
  const sharedProps = {
    isVirtual,
    submitButtonProps,
    activeStepId,
    onBack,
    user,
  };

  const steps: TStep[] = [
    {
      component: OxBookingSimpleStep1,
      props: {
        ...sharedProps,
      },
    },
    {
      component: OxBookingSimpleStep2,
      isDisabled: isVirtual || state.hasPaymentMethods || paymentDisabled,
      props: {
        ...sharedProps,
      },
    },
  ];

  const enabledSteps = steps.filter((step) => !step.isDisabled);

  const onNext = (): void => {
    setActiveStepId((prev) => {
      const isLastStep = enabledSteps.length - 1 === prev;

      if (isLastStep) formSubmit();

      return isLastStep ? enabledSteps.length - 1 : prev + 1;
    });
  };

  return (
    <Styled.AnimatedBoxWrapper>
      {enabledSteps.map((step, stepId) => (
        <Styled.AnimationContainer
          key={stepId}
          active={stepId === activeStepId}
          animation={EAnimatedWrapperAnimation.FadeIn}
          isNext={stepId - activeStepId > 0}
        >
          {React.createElement<TStepProps>(step.component, {
            onNext,
            active: stepId === activeStepId,
            ...step.props,
          })}
        </Styled.AnimationContainer>
      ))}
    </Styled.AnimatedBoxWrapper>
  );
};
