import styled from "styled-components";
import { OxCheckbox } from "src/components/OxCheckbox";
import { fluidSizing } from "src/utils";
import { createTextStyle } from "src/utils/createTextStyle";
import { EFonts, EFontWeight } from "src/config/enums";
import { OxLink } from "src/components/OxLink";
import { OxContainer } from "src/components/OxContainer";
import { OxAnimatedWrapper } from "src/components/OxAnimatedWrapper";

export const DetailsHeading = styled.h2`
  ${createTextStyle(
    EFonts.Hatton,
    EFontWeight.Medium,
    [22, 22, 30],
    [28, 28, 38]
  )}
  ${fluidSizing([{ prop: "margin-bottom", values: [15, 20, 20] }])}
`;

export const Wrapper = styled.div`
  & > * {
    ${fluidSizing([{ prop: "margin-bottom", values: [18, 18, 18] }])}
  }
`;

export const Note = styled.div`
  ${createTextStyle(
    EFonts.Montserrat,
    EFontWeight.Normal,
    [10, 10, 10],
    [10, 10, 10]
  )}

  ${fluidSizing([
    { prop: "margin-top", values: [-10, -10, -10] },
    { prop: "padding-left", values: [15, 15, 15] }
  ])}
`;

export const AnimatedBoxWrapper = styled(OxContainer)`
  position: relative;
  flex: 1;
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;

export const AnimationContainer = styled(OxAnimatedWrapper)`
  display: flex;
  flex-direction: column;
`;

export const Link = styled(OxLink)`
  display: inline-block;
  text-decoration: underline;
`;

export const Checkbox = styled(OxCheckbox)`
  ${fluidSizing([{ prop: "margin-bottom", values: [20, 20, 20] }])}
`;
